/* eslint-disable @typescript-eslint/no-throw-literal */
/* eslint-disable no-param-reassign */
import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import {
  CardItemResponseDto,
  CardResponseDto,
  CardType,
  DeckDetailResponseDto,
  DeckResponseType,
  DecksCuratingResponseDto,
  DecksFollowingWebResponseDto,
  FeaturedPromotionUpdateResponseDto,
  ImageTarget,
  PromotionCountsResponseDto,
  PromotionsResponseDto,
  ShortcutResponseType,
  ShortcutsResponseWebAppDto,
  ShortcutType,
  Uon,
  UserCountsCustomResponseDto,
} from "@earthtoday/contract";
import { AxiosResponse } from "axios";

import { PromotionCount } from "../../../__generated__/ts-gql/@schema";
import { CampaignDefaultResponse } from "../../stores/CampaignDefaultModel";
import { ProfileRepost } from "../../stores/CardDeckOverviewModel";
import {
  CardOnboardingAction,
  CardOnboardingType,
} from "../../stores/CardOnboardingModel";
import { MetaTag } from "../../stores/MetaStore";
import {
  ChangeQuoteResponse,
  QueryMetaParams,
} from "../../stores/ProfilePagePresenter";
import { TokenInterceptorStore } from "../../stores/TokenInterceptorStore";
import { UserModel } from "../../stores/UserModel";
import { getAPIBaseUrl } from "../env";
import { getGraphqlClient } from "../graphqlClient";
import { getApiMetaUrl } from "../helpers/getApiMetaUrl";
import { hashFile } from "../helpers/hashedFile";
import { isBrowser } from "../helpers/isBrowser";
import { VanityNameChangedError } from "../helpers/VanityNameChangedError";
import { CampaignDefaultEditResponse } from "../models/CampaignDefault";
import { Card, CardSize } from "../models/Card";
import { IConsumer } from "../models/Consumer";
import { IDeckExplore } from "../models/DeckExplore";
import { User, UserProfile } from "../models/User";
import { SubscribeCard } from "./../models/SubscribeCard";
import { campaignLeaderboardTopUpdatedSubscription } from "./campaignDefaultApiQueries";
import {
  charityReceivedCountUpdatedSubscription,
  uonCharityCountUpdatedSubscription,
} from "./charityPageQueries";
import { QueryLeaderboard } from "./LeaderboardPageAPI";
import { campaignLeaderboardUpdatedSubscription } from "./LeaderBoardQueries";
import { ApiOption, PaginationOption } from "./options/ApiOption";
import {
  brandClaimCountUpdatedSubscription,
  npoCountUpdatedSubscription,
  promotionCountUpdatedSubscription,
  userProfileCountUpdatedSubscription,
} from "./profileApiQueries";
import { uonCountUpdatedSubscription } from "./protectPageQueries";
import { UnsubscribeFn } from "./UnsubscribeFn";
import { UserSessionApi } from "./UserSessionApi";

export type IDeckFollowing = {
  decks: DeckDetailResponseDto[];
};

export type DeckFollowingInfo = {
  decks: Omit<IDeckExplore, "following">[];
};

export type DecksFollowingStatus = Array<{
  id: string;
  count: number;
  following: boolean;
}>;

export type CardOnboardingsResponse = {
  type: CardOnboardingType;
  visible: boolean;
};

type UpdateUserCardOnboardingResponse = {
  type: string;
  visitedAt?: string;
  closedAt?: string;
};

export type ShortcutResponse = {
  id: string;
  name: string;
  type: ShortcutType;
  path: string[];
};

export interface IProfileApi {
  fetchDecks(vanityName: string): Promise<DecksCuratingResponseDto>;
  fetchMetaData(
    vanityName: string,
    queryMetaParams?: QueryMetaParams,
  ): Promise<MetaTag>;
  fetchProfileDetail(
    vanityName: string,
    queryMetaParams?: QueryMetaParams,
  ): Promise<User & { defaultCampaign: CampaignDefaultResponse }>;
  fetchFollowingDecks(
    vanityName: string,
    options?: PaginationOption,
  ): Promise<DecksFollowingWebResponseDto>;
  fetchFollowingDecksStatus(
    profileIdOrVanityName: string,
    callerId: string,
    sortedDeckIds: string[],
  ): Promise<DecksFollowingStatus>;
  fecthPublishedCards(options?: ApiOption): Promise<CardResponseDto[]>;
  fecthStarredCards(): Promise<Card[]>;
  fecthDraftCards(options?: ApiOption): Promise<CardItemResponseDto[]>;
  fecthSquareMetters(options?: ApiOption): Promise<Uon[]>;
  fetchUserCounts(options?: ApiOption): Promise<UserCountsCustomResponseDto>;
  fetchUserCountsById(
    id: string,
    options?: ApiOption,
  ): Promise<UserCountsCustomResponseDto>;
  updateFeatureCard(cardId: string, featured: boolean): Promise<User>;
  deleteDeck(deckId: string): Promise<null>;
  deleteCard(cardId: string, targetDeckId?: string): Promise<null>;
  deletePromotionCard(
    idOrVanityName: string,
    promotionId: string,
  ): Promise<void>;
  changeQuote(quote: string, id: string): Promise<ChangeQuoteResponse>;
  deleteCoverPhoto(vanityName: string): Promise<UserProfile>;
  subscribeCharityReceivedCount(
    charityUserId: string,
    subscribeFn: (error: Error | null, uonCount: number) => void,
  ): UnsubscribeFn;
  subscribeCharityCount(
    charityUserId: string,
    subscribeFn: (error: Error | null, uonCount: number) => void,
  ): UnsubscribeFn;
  subscribeNPOCount(
    npoUserId: string,
    subscribeFn: (error: Error | null, uonCount: number) => void,
  ): UnsubscribeFn;
  subscribeBrandCount(
    brandUserId: string,
    subscribeFn: (error: Error | null, uonCount: number) => void,
  ): UnsubscribeFn;
  subscribeUserProfileCount(
    userId: string,
    subscribeFn: (error: Error | null, uonCount: number) => void,
  ): UnsubscribeFn;
  subscribeGlobalUonCount(
    subscribeFn: (error: Error | null, count: number) => void,
  ): UnsubscribeFn;
  subscribePromotionCount(
    promotionId: string,
    subscribeFn: (data: Omit<PromotionCount, "__typename">) => void,
  ): UnsubscribeFn;
  fetchPromotions: (
    userId: string,
    params?: { promoName?: string; limit?: string },
  ) => Promise<PromotionsResponseDto>;
  presignImage(
    photo: File,
    imageTarget?: ImageTarget,
  ): Promise<{ url: string; uploadToken: string }>;
  deletePresignImage(
    uploadToken: string,
    reason: string,
  ): Promise<AxiosResponse>;
  uploadToS3(url: string, photo: File): Promise<Response>;
  commitUploadImage(
    updatedUser: string,
    uploadToken: string,
  ): Promise<AxiosResponse>;
  fetchLeaderboardTop(query?: QueryLeaderboard): Promise<IConsumer[]>;
  fetchLeaderboardRecent(query?: QueryLeaderboard): Promise<IConsumer[]>;
  subscribeCampaignLeaderboard(
    id: string,
    subscribeFn: (
      error: Error | null,
      campaignLeaderboardUpdated: IConsumer[] | null,
    ) => void,
  ): UnsubscribeFn;
  subscribeCampaignTopLeaderboard(
    id: string,
    subscribeFn: (
      error: Error | null,
      campaignLeaderboardTopUpdated: IConsumer[] | null,
    ) => void,
  ): UnsubscribeFn;

  changeTitleCampaignDefault(
    name: string,
  ): Promise<CampaignDefaultEditResponse>;

  fetchProfileWithMetaData(
    vanityName: string,
    queryMetaParams?: QueryMetaParams,
  ): Promise<UserModel>;

  fetchCardOnboardings(
    userIdOrVanityName: string,
  ): Promise<CardOnboardingsResponse>;

  saveUserCardOnboarding(
    userIdOrVanityName: string,
    type: string,
    action: CardOnboardingAction,
  ): Promise<UpdateUserCardOnboardingResponse>;

  getDeckShortcuts(
    userIdOrVanityName: string,
  ): Promise<ShortcutsResponseWebAppDto>;

  saveDeckShortcut(
    userIdOrVanityName: string,
    deckId: string,
    isShortcut: boolean,
    type: ShortcutType,
  ): Promise<ShortcutResponse & { isShortcut: boolean }>;

  fetchProfileRepost(vanityName: string): Promise<ProfileRepost>;

  fetchSubscribeCards(): Promise<SubscribeCard[]>;
  fetchProfileByUserId(userId: string): Promise<User>;
  fetchPromotionCounts: (
    promotionIds: string[],
  ) => Promise<PromotionCountsResponseDto>;
  fetchCollectedPromotions: (userId: string) => Promise<PromotionsResponseDto>;
  featurePromotion: (
    promotionId: string,
    isFeatured: boolean,
  ) => Promise<FeaturedPromotionUpdateResponseDto>;
  updateReserveDefaultCollectible: (
    reserveId: string,
    promotionId: string,
  ) => Promise<void>;
}

export class ProfileApi implements IProfileApi {
  constructor(
    private tokenInterceptorStore: TokenInterceptorStore,
    private userSessionApi: UserSessionApi,
    private apolloClient?: ApolloClient<NormalizedCacheObject>,
  ) {}

  subscribeCharityReceivedCount = (
    charityUserId: string,
    subscribeFn: (error: Error | null, uonCount: number) => void,
  ): UnsubscribeFn => {
    if (!isBrowser()) {
      return () => {};
    }

    const observable = getGraphqlClient()
      .subscribe<{ charityReceivedCountUpdated: { count: number } }>({
        query: charityReceivedCountUpdatedSubscription() as any,
        variables: {
          id: charityUserId,
        },
        context: {
          tokenInterceptorStore: this.tokenInterceptorStore,
        },
      })
      .subscribe((result) => {
        if (Array.isArray(result.errors) && result.errors.length > 0) {
          subscribeFn(new Error(result.errors.toString()), -1);
          return;
        }
        subscribeFn(null, result.data?.charityReceivedCountUpdated.count || 0);
      });

    return () => observable.unsubscribe();
  };

  subscribeCharityCount = (
    charityUserId: string,
    subscribeFn: (error: Error | null, uonCount: number) => void,
  ): UnsubscribeFn => {
    if (!isBrowser()) {
      return () => {};
    }

    const observable = getGraphqlClient()
      .subscribe<{ charityCountUpdated: { count: number } }>({
        query: uonCharityCountUpdatedSubscription() as any,
        variables: {
          id: charityUserId,
        },
        context: {
          tokenInterceptorStore: this.tokenInterceptorStore,
        },
      })
      .subscribe((result) => {
        if (Array.isArray(result.errors) && result.errors.length > 0) {
          subscribeFn(new Error(result.errors.toString()), -1);
          return;
        }
        subscribeFn(null, result.data?.charityCountUpdated.count || 0);
      });

    return () => observable.unsubscribe();
  };

  subscribeNPOCount = (
    npoUserId: string,
    subscribeFn: (error: Error | null, uonCount: number) => void,
  ): UnsubscribeFn => {
    if (!isBrowser()) {
      return () => {};
    }

    const observable = getGraphqlClient()
      .subscribe<{ npoCountUpdated: { count: number } }>({
        query: npoCountUpdatedSubscription() as any,
        variables: {
          id: npoUserId,
        },
        context: {
          tokenInterceptorStore: this.tokenInterceptorStore,
        },
      })
      .subscribe((result) => {
        if (Array.isArray(result.errors) && result.errors.length > 0) {
          subscribeFn(new Error(result.errors.toString()), -1);
          return;
        }
        subscribeFn(null, result.data?.npoCountUpdated.count || 0);
      });

    return () => observable.unsubscribe();
  };

  subscribeBrandCount = (
    brandUserId: string,
    subscribeFn: (error: Error | null, uonCount: number) => void,
  ): UnsubscribeFn => {
    if (!isBrowser()) {
      return () => {};
    }

    const observable = getGraphqlClient()
      .subscribe<{ brandClaimCountUpdated: { count: number } }>({
        query: brandClaimCountUpdatedSubscription() as any,
        variables: {
          id: brandUserId,
        },
        context: {
          tokenInterceptorStore: this.tokenInterceptorStore,
        },
      })
      .subscribe((result) => {
        if (Array.isArray(result.errors) && result.errors.length > 0) {
          subscribeFn(new Error(result.errors.toString()), -1);
          return;
        }
        subscribeFn(null, result.data?.brandClaimCountUpdated.count || 0);
      });

    return () => observable.unsubscribe();
  };

  subscribeUserProfileCount = (
    userId: string,
    subscribeFn: (error: Error | null, uonCount: number) => void,
  ): UnsubscribeFn => {
    if (!isBrowser()) {
      return () => {};
    }

    const observable = getGraphqlClient()
      .subscribe<{ userProfileCountUpdated: { count: number } }>({
        query: userProfileCountUpdatedSubscription() as any,
        variables: {
          id: userId,
        },
        context: {
          tokenInterceptorStore: this.tokenInterceptorStore,
        },
      })
      .subscribe((result) => {
        if (Array.isArray(result.errors) && result.errors.length > 0) {
          subscribeFn(new Error(result.errors.toString()), -1);
          return;
        }
        subscribeFn(null, result.data?.userProfileCountUpdated.count || 0);
      });

    return () => observable.unsubscribe();
  };

  subscribeGlobalUonCount = (
    subscribeFn: (error: Error | null, uonCount: number) => void,
  ): UnsubscribeFn => {
    if (!isBrowser()) {
      return () => {};
    }

    const observable = getGraphqlClient()
      .subscribe<{ uonCountUpdated: { count: number } }>({
        query: uonCountUpdatedSubscription() as any,
        context: {
          tokenInterceptorStore: this.tokenInterceptorStore,
        },
      })
      .subscribe((result) => {
        if (Array.isArray(result.errors) && result.errors.length > 0) {
          subscribeFn(new Error(result.errors.toString()), -1);
          return;
        }
        subscribeFn(null, result.data?.uonCountUpdated.count || 0);
      });

    return () => observable.unsubscribe();
  };
  fetchDecks = async (
    vanityName: string,
  ): Promise<DecksCuratingResponseDto> => {
    const response =
      await this.tokenInterceptorStore.tsRestClient.deck.getDecksCuratedByAUser(
        {
          params: {
            earthIdOrVanityName: vanityName,
          },
        },
      );
    if (
      response.status === 200 &&
      response.body.responseType === DeckResponseType.WEB_APP
    ) {
      return response.body;
    }

    throw response;
  };

  fetchMetaData = async (
    vanityName: string,
    queryMetaParams?: QueryMetaParams,
  ): Promise<MetaTag> => {
    const time = Date.now();

    if (!queryMetaParams?.channel) return {};

    const baseUrl = getApiMetaUrl(vanityName, queryMetaParams);

    const res = await this.tokenInterceptorStore.call({
      url: `${baseUrl}&ts=${time}`,
    });

    return res.data.metaTags;
  };

  fetchProfileWithMetaData = async (
    vanityName: string,
    queryMetaParams?: QueryMetaParams,
  ): Promise<UserModel> => {
    const time = Date.now();

    const baseUrl = getApiMetaUrl(vanityName, queryMetaParams);

    const res = await this.tokenInterceptorStore.call({
      url: `${baseUrl}&ts=${time}`,
    });

    // server
    if (
      res.request?.res?.responseUrl &&
      res.request.res.responseUrl?.toLowerCase() !==
        res.config.url?.toLowerCase()
    ) {
      const { path } = res.request;
      throw new VanityNameChangedError(
        path
          .split("/")
          .splice(-1)
          .toString()
          .replaceAll(/\?metaTags=.*/g, ""),
      );
    }

    // client
    if (res.data.vanityName !== vanityName) {
      throw new VanityNameChangedError(
        res.data.vanityName.replaceAll(/\?metaTags=.*/g, ""),
      );
    }

    return res.data;
  };

  fetchProfileDetail = async (
    idOrVanityName: string,
    queryMetaParams?: QueryMetaParams,
  ): Promise<User & { defaultCampaign: CampaignDefaultResponse }> => {
    const baseUrl = getApiMetaUrl(idOrVanityName, queryMetaParams);
    const res = await this.tokenInterceptorStore.call({
      url: `${baseUrl}`,
    });
    // server
    if (
      res.request?.res?.responseUrl &&
      res.request.res.responseUrl?.toLowerCase() !==
        res.config.url?.toLowerCase()
    ) {
      const { path } = res.request;
      throw new VanityNameChangedError(
        path
          .split("/")
          .splice(-1)
          .toString()
          .replaceAll(/\?metaTags=.*/g, ""),
      );
    }

    // TODO: remove after updating promotion dto
    // support user id
    if (res.data.id === idOrVanityName) {
      return res.data;
    }

    // client
    if (res.data.vanityName !== idOrVanityName) {
      throw new VanityNameChangedError(
        res.data.vanityName.replaceAll(/\?metaTags=.*/g, ""),
      );
    }

    return res.data;
  };

  fetchFollowingDecks = async (
    vanityName: string,
    options?: PaginationOption,
  ): Promise<DecksFollowingWebResponseDto> => {
    const limit = options?.limit;
    const cursorId = options?.cursorId;
    const params: Record<string, string> = {};
    if (!!limit) params.limit = limit.toString();
    if (!!cursorId) params.cursorId = cursorId;

    const response =
      await this.tokenInterceptorStore.tsRestClient.deck.getDecksFollowedByAUser(
        {
          params: {
            earthIdOrVanityName: vanityName,
          },
          query: {
            cursorId,
            limit: limit ? limit.toString() : undefined,
          },
        },
      );

    if (response.status === 200 && !("responseType" in response.body)) {
      return response.body;
    }

    throw response;
  };

  fetchFollowingDecksStatus = async (
    profileIdOrVanityName: string,
    callerId: string,
    sortedDeckIds: string[] = [],
  ): Promise<DecksFollowingStatus> => {
    const sortedDeckIdsParam =
      sortedDeckIds.length > 0
        ? `&sortedDeckIds=${sortedDeckIds.join(",")}`
        : "";
    const url = `${getAPIBaseUrl()}/users/${profileIdOrVanityName}/followings?callerId=${callerId}${sortedDeckIdsParam}`;
    const res = await this.tokenInterceptorStore.call({
      url,
    });
    return res.data;
  };

  fecthPublishedCards = async (
    options?: ApiOption,
  ): Promise<CardResponseDto[]> => {
    const headers: Record<string, string> = {};
    // bypass
    if (options?.byBass) {
      headers["x-apicache-bypass"] = "true";
    }
    // clear existing cache
    if (options?.clearCache) {
      headers["cache-control"] = "max-age=0";
    }

    // const res: AxiosResponse<{
    //   content: any[];
    // }> = await this.tokenInterceptorStore.call({
    //   url: `${getAPIBaseUrl()}/users/me/cards/published`,
    //   headers,
    // });

    // return res.data.content.map((card) => ({
    //   ...card,
    //   // TODO: clean up
    //   size: card.size.toLowerCase(),
    //   state: card.state.toLowerCase(),
    // }));
    const response =
      await this.tokenInterceptorStore.tsRestClient.card.getMyPublishedCards();

    if (response.status === 200) {
      return response.body.content.map((cardResponse) => {
        const size: string = (() => {
          if (cardResponse.contentType === CardType.PROMOTION_REPOST) {
            return CardSize.SINGLE;
          }

          return cardResponse.size.toLowerCase();
        })();

        return {
          ...cardResponse,
          state: cardResponse.state?.toLocaleLowerCase(),
          size,
        } as CardResponseDto;
      });
    }

    throw response;
  };

  fecthStarredCards = async (): Promise<Card[]> => {
    const res = await this.tokenInterceptorStore.call({
      url: `${getAPIBaseUrl()}/users/me/cards/starred`,
    });
    return res.data;
  };

  fecthDraftCards = async (
    options?: ApiOption,
  ): Promise<CardItemResponseDto[]> => {
    const headers: Record<string, string> = {};
    // bypass
    if (options?.byBass) {
      headers["x-apicache-bypass"] = "true";
    }
    // clear existing cache
    if (options?.clearCache) {
      headers["cache-control"] = "max-age=0";
    }

    const response =
      await this.tokenInterceptorStore.tsRestClient.card.getMyDraftingCards({
        headers,
      });

    if (response.status === 200) {
      return response.body.content;
    }

    throw response;
  };

  fecthSquareMetters = async (options?: ApiOption): Promise<Uon[]> => {
    const headers: Record<string, string> = {};
    // bypass
    if (options?.byBass) {
      headers["x-apicache-bypass"] = "true";
    }
    // clear existing cache
    if (options?.clearCache) {
      headers["cache-control"] = "max-age=0";
    }
    const response =
      await this.tokenInterceptorStore.tsRestClient.uon.getTransactions({
        params: {
          userIdOrVanityName: "me",
        },
        query: {
          offset: (options?.offset || 0).toString(),
        },
        headers,
      });

    if (response.status == 200) {
      return response.body;
    }
    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw response;
  };

  fetchUserCounts = async (
    options?: ApiOption,
  ): Promise<UserCountsCustomResponseDto> => {
    const headers: Record<string, string> = {};
    // bypass
    if (options?.byBass) {
      headers["x-apicache-bypass"] = "true";
    }
    // clear existing cache
    if (options?.clearCache) {
      headers["cache-control"] = "max-age=0";
    }

    const res =
      await this.tokenInterceptorStore.tsRestClient.user.userStatistics({
        params: {
          userIdOrVanityName: "me",
        },
        headers,
      });

    if (res.status === 200) {
      return res.body;
    }

    throw res;
  };

  fetchUserCountsById = async (
    id: string,
    options?: ApiOption,
  ): Promise<UserCountsCustomResponseDto> => {
    const headers: Record<string, string> = {};
    // bypass
    if (options?.byBass) {
      headers["x-apicache-bypass"] = "true";
    }
    // clear existing cache
    if (options?.clearCache) {
      headers["cache-control"] = "max-age=0";
    }

    const res =
      await this.tokenInterceptorStore.tsRestClient.user.userStatistics({
        params: {
          userIdOrVanityName: id,
        },
        headers,
      });

    if (res.status === 200) {
      return res.body;
    }

    throw res;
  };

  updateFeatureCard = async (
    cardId: string,
    featured: boolean,
  ): Promise<User> => {
    const res = await this.tokenInterceptorStore.call({
      url: `${getAPIBaseUrl()}/users/me/featured`,
      method: "PUT",
      data: { cardId, featured },
    });

    return res.data;
  };

  deleteDeck = async (deckId: string): Promise<null> => {
    const res = await this.tokenInterceptorStore.call({
      url: `${getAPIBaseUrl()}/decks/${deckId}`,
      method: "DELETE",
    });
    return res.data;
  };

  deleteCard = async (cardId: string, targetDeckId?: string): Promise<null> => {
    const params: Record<string, string> = {};
    if (!!targetDeckId) params.targetDeckId = targetDeckId;

    const queryString = new URLSearchParams(params).toString();

    const res = await this.tokenInterceptorStore.call({
      url: `${getAPIBaseUrl()}/cards/${cardId}?${queryString}`,
      method: "DELETE",
    });

    return res.data;
  };

  deletePromotionCard = async (
    idOrVanityName: string,
    promotionId: string,
  ): Promise<void> => {
    const res =
      await this.tokenInterceptorStore.tsRestClient.promotion.deletePromotion({
        params: {
          idOrVanityName,
          promotionId,
        },
        body: null,
      });

    if (res.status === 204) {
      return;
    }

    throw res;
  };

  presignImage = async (
    cover: File,
    imageTarget?: ImageTarget,
  ): Promise<{ url: string; uploadToken: string }> => {
    const hashedFile = await hashFile(cover);
    const fileName = cover.name;
    const resPresignImage = await this.tokenInterceptorStore.call({
      method: "POST",
      url: `${getAPIBaseUrl()}/uploads/presign`,
      data: {
        fileName,
        contentType: cover.type,
        target: imageTarget || ImageTarget.USER_COVER,
        hashedFile,
      },
    });

    return resPresignImage.data;
  };

  deletePresignImage = async (
    uploadToken: string,
    reason: string,
  ): Promise<AxiosResponse> => {
    const res = await this.tokenInterceptorStore.call({
      method: "DELETE",
      url: `${getAPIBaseUrl()}/images/presign`,
      data: {
        uploadToken,
        reason,
      },
    });

    return res;
  };

  uploadToS3 = async (url: string, cover: File): Promise<Response> => {
    const hashedFile = await hashFile(cover);
    const resUploadToS3 =
      await this.tokenInterceptorStore.callWithoutExtraConfigs({
        method: "PUT",
        headers: {
          "Content-Type": cover.type,
          "Content-Encoding": "utf8",
          "Content-MD5": hashedFile,
        },
        data: cover,
        url,
      });
    return resUploadToS3;
  };

  commitUploadImage = async (
    updatedUser: string,
    uploadToken: string,
  ): Promise<AxiosResponse> => {
    const res = await this.tokenInterceptorStore.call({
      method: "PUT",
      url: `${getAPIBaseUrl()}/users/${updatedUser}/cover/commit`,
      data: {
        uploadToken,
        target: ImageTarget.USER_COVER,
      },
    });
    return res;
  };

  changeQuote = async (
    quote: string,
    id = "me",
  ): Promise<ChangeQuoteResponse> => {
    const changeFor: string = id;
    const res = await this.tokenInterceptorStore.call({
      url: `${getAPIBaseUrl()}/users/${changeFor}/details`,
      method: "PUT",
      data: {
        quote,
      },
    });

    return res.data;
  };

  deleteCoverPhoto = async (vanityName: string): Promise<UserProfile> => {
    const res = await this.tokenInterceptorStore.call({
      url: `${getAPIBaseUrl()}/users/${vanityName}/cover`,
      method: "DELETE",
    });

    return res.data;
  };

  fetchLeaderboardTop = async (
    query?: QueryLeaderboard,
  ): Promise<IConsumer[]> => {
    if (!query) {
      const res = await this.tokenInterceptorStore.call({
        url: `${getAPIBaseUrl()}/uon/leaderboard?type=TOP&count=50`,
      });
      return res.data;
    }

    const res = await this.tokenInterceptorStore.call({
      url: `${getAPIBaseUrl()}/uon/leaderboard?type=TOP&idOrVanityName=${
        query.idOrVanityName
      }&sourceType=${query.sourceType}&count=50`,
    });
    return res.data;
  };

  fetchLeaderboardRecent = async (
    query?: QueryLeaderboard,
  ): Promise<IConsumer[]> => {
    if (!query) {
      const res = await this.tokenInterceptorStore.call({
        url: `${getAPIBaseUrl()}/uon/leaderboard?type=RECENT&count=50`,
      });
      return res.data;
    }

    const res = await this.tokenInterceptorStore.call({
      url: `${getAPIBaseUrl()}/uon/leaderboard?type=RECENT&idOrVanityName=${
        query.idOrVanityName
      }&sourceType=${query.sourceType}&count=50`,
    });
    return res.data;
  };

  subscribeCampaignLeaderboard = (
    id: string,
    subscribeFn: (
      error: Error | null,
      campaignLeaderboardUpdated: IConsumer[] | null,
    ) => void,
  ): UnsubscribeFn => {
    const observable = getGraphqlClient()
      .subscribe<{ campaignLeaderboardUpdated: IConsumer[] | null }>({
        query: campaignLeaderboardUpdatedSubscription() as any,
        variables: {
          id,
        },
        context: {
          tokenInterceptorStore: this.tokenInterceptorStore,
        },
      })
      .subscribe((result) => {
        if (Array.isArray(result.errors) && result.errors.length > 0) {
          subscribeFn(new Error(result.errors.toString()), null);
          return;
        }
        subscribeFn(null, result.data?.campaignLeaderboardUpdated || null);
      });

    return () => observable.unsubscribe();
  };

  subscribeCampaignTopLeaderboard = (
    id: string,
    subscribeFn: (
      error: Error | null,
      campaignLeaderboardTopUpdated: IConsumer[] | null,
    ) => void,
  ): UnsubscribeFn => {
    const observable = getGraphqlClient()
      .subscribe<{ campaignLeaderboardTopUpdated: IConsumer[] | null }>({
        query: campaignLeaderboardTopUpdatedSubscription() as any,
        variables: {
          id,
        },
        context: {
          tokenInterceptorStore: this.tokenInterceptorStore,
        },
      })
      .subscribe((result) => {
        if (Array.isArray(result.errors) && result.errors.length > 0) {
          subscribeFn(new Error(result.errors.toString()), null);
          return;
        }
        subscribeFn(null, result.data?.campaignLeaderboardTopUpdated || null);
      });

    return () => observable.unsubscribe();
  };

  changeTitleCampaignDefault = async (
    name: string,
  ): Promise<CampaignDefaultEditResponse> => {
    const res = await this.tokenInterceptorStore.call({
      method: "PUT",
      url: `${getAPIBaseUrl()}/users/me/campaigns/default`,
      data: {
        name,
      },
    });
    return res.data;
  };

  fetchCardOnboardings = async (
    userIdOrVanityName: string,
  ): Promise<CardOnboardingsResponse> => {
    const res = await this.tokenInterceptorStore.call({
      method: "GET",
      url: `${getAPIBaseUrl()}/users/${userIdOrVanityName}/card_onboardings`,
    });
    return res.data;
  };

  saveUserCardOnboarding = async (
    userIdOrVanityName: string,
    type: string,
    action: CardOnboardingAction,
  ): Promise<UpdateUserCardOnboardingResponse> => {
    const res = await this.tokenInterceptorStore.call({
      method: "PUT",
      url: `${getAPIBaseUrl()}/users/card_onboardings`,
      data: {
        userIdOrVanityName,
        type,
        action,
      },
    });
    return res.data;
  };

  getDeckShortcuts = async (
    userIdOrVanityName: string,
  ): Promise<ShortcutsResponseWebAppDto> => {
    const response =
      await this.tokenInterceptorStore.tsRestClient.user.getShortcutsByUserV2({
        params: {
          userIdOrVanityName,
        },
        query: {
          responseType: ShortcutResponseType.WEB_APP,
        },
      });

    if (response.status == 200) {
      if (response.body.responseType !== ShortcutResponseType.WEB_APP) {
        throw new Error(
          `Unsupported shortcuts response type: ${response.body.responseType}`,
        );
      }

      return response.body;
    }

    throw response;
  };

  saveDeckShortcut = async (
    userIdOrVanityName: string,
    deckId: string,
    isShortcut: boolean,
    type: ShortcutType,
  ): Promise<ShortcutResponse & { isShortcut: boolean }> => {
    const res = await this.tokenInterceptorStore.call({
      method: "PATCH",
      url: `${getAPIBaseUrl()}/users/${userIdOrVanityName}/shortcuts`,
      data: {
        earthId: deckId,
        type,
        isShortcut,
      },
    });
    return res.data;
  };

  fetchProfileRepost = async (vanityName: string): Promise<ProfileRepost> => {
    const res = await this.tokenInterceptorStore.call({
      url: `${getAPIBaseUrl()}/user_profiles/${vanityName}/?metaTags=${!isBrowser()}`,
    });

    return res.data;
  };

  fetchSubscribeCards = async (): Promise<SubscribeCard[]> => {
    const res = await this.tokenInterceptorStore.call({
      url: `${getAPIBaseUrl()}/users/me/subscriptions`,
    });

    return res.data;
  };

  fetchProfileByUserId = async (userId: string): Promise<User> => {
    const res = await this.tokenInterceptorStore.call({
      url: `${getAPIBaseUrl()}/users/${userId}/?metaTags=${!isBrowser()}`,
    });

    return res.data;
  };

  fetchPromotions = async (
    userId: string,
    params?: { promoName?: string; limit?: string },
  ) => {
    const response =
      await this.tokenInterceptorStore.tsRestClient.promotion.getPromotions({
        params: {
          idOrVanityName: userId,
        },
        query: {
          metaPromotionName: params?.promoName || undefined,
          limit: params?.limit || undefined,
        },
      });

    if (response.status == 200) {
      return response.body;
    }

    throw response;
  };

  fetchPromotionCounts = async (promotionIds: string[]) => {
    const response =
      await this.tokenInterceptorStore.tsRestClient.promotion.getPromotionCounts(
        {
          query: {
            promotionIds: promotionIds.join(","),
          },
        },
      );

    if (response.status == 200) {
      return response.body;
    }

    throw response;
  };
  subscribePromotionCount = (
    promotionId: string,
    subscribeFn: (
      promotionCountUpdated: Omit<PromotionCount, "__typename">,
    ) => void,
  ): UnsubscribeFn => {
    const disconnect: UnsubscribeFn = () => {};

    if (!isBrowser()) return disconnect;
    const observable = (this.apolloClient || getGraphqlClient())
      .subscribe<{
        promotionCountUpdated: {
          id: string;
          count: number;
        };
      }>({
        query: promotionCountUpdatedSubscription() as any,
        variables: {
          promotionId,
        },
        context: {
          tokenInterceptorStore: this.tokenInterceptorStore,
        },
      })
      .subscribe((result) => {
        if (
          (Array.isArray(result.errors) && result.errors.length > 0) ||
          !result.data
        ) {
          return;
        }
        const { promotionCountUpdated } = result.data;
        subscribeFn(promotionCountUpdated);
      });

    return () => observable.unsubscribe();
  };

  fetchCollectedPromotions = async (
    userId: string,
  ): Promise<PromotionsResponseDto> => {
    const response =
      await this.tokenInterceptorStore.tsRestClient.promotion.getCollectedPromotions(
        {
          params: {
            idOrVanityName: userId,
          },
        },
      );

    if (response.status === 200) {
      return response.body;
    }

    throw response;
  };

  featurePromotion = async (promotionId: string, isFeatured: boolean) => {
    const response =
      await this.tokenInterceptorStore.tsRestClient.promotion.featurePromotion({
        body: {
          data: {
            isFeatured,
          },
        },
        params: {
          promotionId,
        },
      });

    if (response.status === 200) {
      return response.body;
    }

    throw response;
  };

  updateReserveDefaultCollectible = async (
    reserveId: string,
    promotionId: string,
  ): Promise<void> => {
    const response =
      await this.tokenInterceptorStore.tsRestClient.reserveContract.setCollectibleForReserve(
        {
          body: null,
          headers: {
            authorization: undefined as unknown as string,
          },
          params: { reserveId, promotionId },
        },
      );

    if (response.status === 204) {
      return;
    }
    throw response;
  };
}
