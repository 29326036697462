/* eslint-disable @typescript-eslint/no-throw-literal */
/* eslint-disable no-param-reassign */
import {
  BackgroundImageMutationType,
  CardChangeSortResponseDto,
  CardResponseDto,
  DeckDetailResponseDto,
  DeckUpdatePayloadDto,
  MobileSystemDeckResponseDto,
  MobileSystemDeckSavePayloadDto,
  PromotionsResponseDto,
  UserCountsCustomResponseDto,
} from "@earthtoday/contract";

import { TokenInterceptorStore } from "../../stores/TokenInterceptorStore";
import { getAPIBaseUrl } from "../env";
import { getGraphqlClient } from "../graphqlClient";
import { RequireAtLeastOne } from "../helpers/commonValidations";
import { DeckNameChangedError } from "../helpers/DeckNameChangedError";
import { isBrowser } from "../helpers/isBrowser";
import { VanityNameChangedError } from "../helpers/VanityNameChangedError";
import {
  Card,
  CardAction,
  CardArticleLayoutType,
  CardInfo,
  CardRegular,
  CardSize,
  CardTextAlignment,
  CardTextColor,
  CardType,
} from "../models/Card";
import { IConsumer } from "../models/Consumer";
import { IDeckDetail } from "../models/DeckDetail";
import { IDeckUpdateResponse } from "../models/DeckExplore";
import { User } from "../models/User";
import {
  reserveCountUpdatedSubscription,
  reserveLeaderboardUpdatedSubscription,
} from "./deckDetailApiQueries";
import { ApiOption } from "./options/ApiOption";
import { UnsubscribeFn } from "./UnsubscribeFn";
import { UserSessionApi } from "./UserSessionApi";

type CardActionUpdateContentProps = {
  type: CardType.ACTION;
  textAlignment?: CardTextAlignment;
  textColor?: CardTextColor;
  buttonText?: string;
  size?: string;
};
type CardActionUpdateContentPayload = RequireAtLeastOne<
  CardActionUpdateContentProps,
  "textAlignment" | "textColor" | "buttonText" | "size"
>;
type CardInfoUpdateContentProps = {
  type: CardType.INFO;
  textAlignment: CardTextAlignment | undefined;
  textColor: CardTextColor | undefined;
  backgroundColor: string;
};
type CardInfoUpdateContentPayload = RequireAtLeastOne<
  CardInfoUpdateContentProps,
  "textAlignment" | "textColor" | "backgroundColor"
>;

export interface IDeckDetailApi {
  fetchDeckDetail(
    vanityName: string,
    deckName: string,
    options?: ApiOption,
  ): Promise<DeckDetailResponseDto>;
  updateFeatureCard(
    deckId: string,
    cardId: string,
    featured: boolean,
  ): Promise<DeckDetailResponseDto>;
  fetchReserveCount(id: string): Promise<{
    uonPreserved: number;
  }>;
  resizeCard(
    cardId: string,
    size: CardSize,
  ): Promise<CardRegular | CardAction | CardInfo>;
  onEditCardAction(
    cardId: string,
    contentPayload: CardActionUpdateContentPayload,
  ): Promise<CardAction>;
  onEditCardInfo(
    cardId: string,
    contentPayload: CardInfoUpdateContentPayload,
  ): Promise<CardInfo>;
  onPublishCardAction(cardId: string): Promise<CardAction>;
  onChangeCardLayout(
    cardId: string,
    layoutType: CardArticleLayoutType,
  ): Promise<Card>;
  fetchProfile(vanityName: string, options?: ApiOption): Promise<User>;
  fetchLeaderboardTop(id: string): Promise<IConsumer[]>;
  fetchLeaderboardRecent(id: string): Promise<IConsumer[]>;
  editDeck(
    deckId: string,
    payload: DeckUpdatePayloadDto,
  ): Promise<DeckDetailResponseDto>;
  fetchUserCountsById(
    id: string,
    options?: ApiOption,
  ): Promise<UserCountsCustomResponseDto>;
  subscribeReserveCount(
    reserveId: string,
    subscribeFn: (error: Error | null, uonCount: number) => void,
  ): UnsubscribeFn;
  subscribeReserveLeaderboard(
    reserveId: string,
    subscribeFn: (
      error: Error | null,
      reserveLeaderboardUpdated: IConsumer[] | null,
    ) => void,
  ): UnsubscribeFn;
  updateCardOrder(
    userIdOrVanityName: string,
    deckId: string,
    cardId: string,
    sortIndex: number,
  ): Promise<CardChangeSortResponseDto>;
  getMobileSystemDeckDetail(
    deckId: string,
  ): Promise<MobileSystemDeckResponseDto>;
  saveMobileSystemDeck(
    payload: MobileSystemDeckSavePayloadDto,
  ): Promise<MobileSystemDeckResponseDto>;
  fetchPromotions(
    userId: string,
    params?: { promoName?: string; limit?: string },
  ): Promise<PromotionsResponseDto>;
}

export type DeckEditBackGroundImageType =
  | {
      type: BackgroundImageMutationType.NOOP;
    }
  | {
      type: BackgroundImageMutationType.REMOVAL;
    }
  | {
      type: BackgroundImageMutationType.UPLOAD;
      file: File;
    };

export interface DeckEditData {
  name: string;
  description: string;
  backgroundImage: DeckEditBackGroundImageType;
}

export class DeckDetailApi implements IDeckDetailApi {
  constructor(
    private tokenInterceptorStore: TokenInterceptorStore,
    private userSessionApi: UserSessionApi,
  ) {}

  subscribeReserveCount = (
    reserveId: string,
    subscribeFn: (error: Error | null, uonCount: number) => void,
  ): UnsubscribeFn => {
    if (!isBrowser()) {
      return () => {};
    }

    const observable = getGraphqlClient()
      .subscribe<{ reserveCountUpdated: { count: number } }>({
        query: reserveCountUpdatedSubscription() as any,
        variables: {
          id: reserveId,
        },
        context: {
          tokenInterceptorStore: this.tokenInterceptorStore,
        },
      })
      .subscribe((result) => {
        if (Array.isArray(result.errors) && result.errors.length > 0) {
          subscribeFn(new Error(result.errors.toString()), -1);
          return;
        }
        subscribeFn(null, result.data?.reserveCountUpdated.count || 0);
      });

    return () => observable.unsubscribe();
  };

  subscribeReserveLeaderboard = (
    reserveId: string,
    subscribeFn: (
      error: Error | null,
      reserveLeaderboardUpdated: IConsumer[] | null,
    ) => void,
  ): UnsubscribeFn => {
    if (!isBrowser()) {
      return () => {};
    }

    const observable = getGraphqlClient()
      .subscribe<{ reserveLeaderboardUpdated: IConsumer[] | null }>({
        query: reserveLeaderboardUpdatedSubscription() as any,
        variables: {
          id: reserveId,
        },
        context: {
          tokenInterceptorStore: this.tokenInterceptorStore,
        },
      })
      .subscribe((result) => {
        if (Array.isArray(result.errors) && result.errors.length > 0) {
          subscribeFn(new Error(result.errors.toString()), null);
          return;
        }
        subscribeFn(null, result.data?.reserveLeaderboardUpdated || null);
      });

    return () => observable.unsubscribe();
  };

  fetchDeckDetail = async (
    vanityName: string,
    deckName: string,
    options?: ApiOption,
  ): Promise<DeckDetailResponseDto> => {
    const headers: Record<string, string> = {};
    if (options?.byBass) {
      headers["x-apicache-bypass"] = "true";
    }
    if (options?.clearCache) {
      headers["cache-control"] = "max-age=0";
    }

    const response =
      await this.tokenInterceptorStore.tsRestClient.deck.retrieveDeckByIdAndLinkName(
        {
          params: {
            earthIdOrVanityName: vanityName,
            linkName: deckName,
          },
        },
      );

    if (response.status === 200) {
      // // server
      // if (
      //   res.request?.res?.responseUrl &&
      //   res.request.res.responseUrl?.toLowerCase() !==
      //     res.config.url?.toLowerCase()
      // ) {
      //   const { path } = res.request;
      //   const deckPath = path.split("/").splice(-2);

      //   throw new DeckNameChangedError(deckPath[0], deckPath[1]);
      // }

      // client
      if (
        response.body.data.path.toString() !== [vanityName, deckName].toString()
      ) {
        throw new DeckNameChangedError(
          response.body.data.path[0],
          response.body.data.path[1],
        );
      }
      const cards = response.body.data.cards.map((cardResponse) => {
        const size: string = (() => {
          if (cardResponse.contentType === CardType.PROMOTION_REPOST) {
            return CardSize.SINGLE;
          }

          return cardResponse.size.toLowerCase();
        })();

        return {
          ...cardResponse,
          state: cardResponse.state?.toLocaleLowerCase(),
          size,
        } as CardResponseDto;
      });
      const data = { ...response.body.data, cards };
      return { data, meta: response.body.meta };
    }
    throw response;
  };

  updateFeatureCard = async (
    deckId: string,
    cardId: string,
    featured: boolean,
  ): Promise<DeckDetailResponseDto> => {
    const response =
      await this.tokenInterceptorStore.tsRestClient.deck.featureCard({
        params: {
          earthId: deckId,
        },
        body: { cardId, featured },
      });

    if (response.status === 200) {
      return response.body;
    }

    throw response;
  };

  fetchReserveCount = async (
    id: string,
  ): Promise<{
    uonPreserved: number;
  }> => {
    const res = await this.tokenInterceptorStore.call({
      url: `${getAPIBaseUrl()}/uon/reserves/${id}/uons/count?filter=ALL`,
    });

    return res.data;
  };

  resizeCard = async (
    cardId: string,
    size: CardSize,
  ): Promise<CardRegular | CardAction | CardInfo> => {
    const res = await this.tokenInterceptorStore.call({
      url: `${getAPIBaseUrl()}/cards/${cardId}/size`,
      data: { size },
      method: "PUT",
    });
    return {
      ...res.data,
      state: res.data.state?.toLocaleLowerCase(),
      size: res.data.size?.toLowerCase(),
    };
  };
  onEditCardAction = async (
    cardId: string,
    contentPayload: CardActionUpdateContentPayload,
  ): Promise<CardAction> => {
    const res = await this.tokenInterceptorStore.call({
      url: `${getAPIBaseUrl()}/cards/${cardId}`,
      method: "PUT",
      data: { data: { content: contentPayload } },
    });

    const card = res.data.data;
    return {
      ...card,
      state: card.state?.toLocaleLowerCase(),
      size: card.size?.toLowerCase(),
    };
  };

  onPublishCardAction = async (cardId: string): Promise<CardAction> => {
    const res = await this.tokenInterceptorStore.call({
      url: `${getAPIBaseUrl()}/cards/${cardId}`,
      method: "PUT",
      data: {
        data: {
          state: "PUBLISHED",
          content: {
            type: "ACTION",
          },
        },
      },
    });

    const card = res.data.data;
    return {
      ...card,
      state: card.state?.toLocaleLowerCase(),
      size: card.size?.toLowerCase(),
    };
  };

  onEditCardInfo = async (
    cardId: string,
    contentPayload: CardInfoUpdateContentPayload,
  ): Promise<CardInfo> => {
    const res = await this.tokenInterceptorStore.call({
      url: `${getAPIBaseUrl()}/cards/${cardId}`,
      method: "PUT",
      data: { data: { content: contentPayload } },
    });
    const card = res.data.data;
    return {
      ...card,
      state: card.state?.toLocaleLowerCase(),
      size: card.size?.toLowerCase(),
    };
  };

  onChangeCardLayout = async (
    cardId: string,
    layoutType: CardArticleLayoutType,
  ): Promise<Card> => {
    const res = await this.tokenInterceptorStore.call({
      url: `${getAPIBaseUrl()}/cards/${cardId}/layout`,
      method: "PATCH",
      data: { layoutType },
    });
    return res.data;
  };

  fetchProfile = async (vanityName: string): Promise<User> => {
    const res = await this.tokenInterceptorStore.call({
      url: `${getAPIBaseUrl()}/users/${vanityName}?metaTags=${!isBrowser()}`,
    });

    if (
      res.request?.res?.responseUrl &&
      res.request.res.responseUrl?.toLowerCase() !==
        res.config.url?.toLowerCase()
    ) {
      const { path } = res.request;
      throw new VanityNameChangedError(
        path
          .split("/")
          .splice(-1)
          .toString()
          .replaceAll(/\?metaTags=.*/g, ""),
      );
    }

    return res.data;
  };

  fetchLeaderboardTop = async (id: string): Promise<IConsumer[]> => {
    const res = await this.tokenInterceptorStore.call({
      url: `${getAPIBaseUrl()}/uon/leaderboard?type=TOP&reserveEarthId=${id}&count=50`,
    });
    return res.data;
  };
  fetchLeaderboardRecent = async (id: string): Promise<IConsumer[]> => {
    const res = await this.tokenInterceptorStore.call({
      url: `${getAPIBaseUrl()}/uon/leaderboard?type=RECENT&reserveEarthId=${id}&count=50`,
    });
    return res.data;
  };

  editDeck = async (
    deckId: string,
    payload: DeckUpdatePayloadDto,
  ): Promise<DeckDetailResponseDto> => {
    const deckDetailResponse =
      await this.tokenInterceptorStore.tsRestClient.deck.updateDeck({
        body: payload,
        params: {
          earthId: deckId,
        },
      });

    if (deckDetailResponse.status === 200) {
      return deckDetailResponse.body;
    }

    throw deckDetailResponse;
  };

  fetchUserCountsById = async (
    id: string,
    options?: ApiOption,
  ): Promise<UserCountsCustomResponseDto> => {
    const headers: Record<string, string> = {};
    // bypass
    if (options?.byBass) {
      headers["x-apicache-bypass"] = "true";
    }
    // clear existing cache
    if (options?.clearCache) {
      headers["cache-control"] = "max-age=0";
    }

    const res =
      await this.tokenInterceptorStore.tsRestClient.user.userStatistics({
        params: {
          userIdOrVanityName: id,
        },
        headers,
      });

    if (res.status === 200) {
      return res.body;
    }

    throw res;
  };

  updateCardOrder = async (
    userIdOrVanityName: string,
    deckId: string,
    cardId: string,
    sortIndex: number,
  ): Promise<CardChangeSortResponseDto> => {
    const response =
      await this.tokenInterceptorStore.tsRestClient.card.changeSortOfCardInDeck(
        {
          params: {
            userIdOrVanityName,
            deckId,
            cardId,
          },
          body: {
            data: {
              sortIndex,
            },
          },
        },
      );
    if (response.status === 200) {
      return response.body;
    }

    throw response;
  };

  getMobileSystemDeckDetail = async (
    deckId: string,
  ): Promise<MobileSystemDeckResponseDto> => {
    const response =
      await this.tokenInterceptorStore.tsRestClient.admin.getMobileSystemDeck({
        params: {
          deckId,
        },
      });

    if (response.status === 200) {
      return response.body;
    }

    throw response;
  };

  saveMobileSystemDeck = async (
    payload: MobileSystemDeckSavePayloadDto,
  ): Promise<MobileSystemDeckResponseDto> => {
    const response =
      await this.tokenInterceptorStore.tsRestClient.admin.saveMobileSystemDeck({
        body: payload,
      });

    if (response.status === 200) {
      return response.body;
    }

    throw response;
  };

  fetchPromotions = async (
    userId: string,
    params?: { promoName?: string; limit?: string },
  ): Promise<PromotionsResponseDto> => {
    const response =
      await this.tokenInterceptorStore.tsRestClient.promotion.getPromotions({
        params: {
          idOrVanityName: userId,
        },
        query: {
          metaPromotionName: params?.promoName || undefined,
          limit: params?.limit || undefined,
        },
      });

    if (response.status == 200) {
      return response.body;
    }

    throw response;
  };
}
