import { MetaTagsDataResponseDto } from "@earthtoday/contract";

import { imageChannelSizeMapping } from "../../stores/imageChannelSizeMapping";
import { MetaTag } from "../../stores/MetaStore";
import { buildResizedAwsImageRequest } from "./buildAwsImageRelated";

export function convertDtoToMetaTags(
  dto: MetaTagsDataResponseDto,
  channel: string = "link",
): MetaTag {
  const size =
    imageChannelSizeMapping[channel] || imageChannelSizeMapping.facebook;
  const resizedImageUrl = buildResizedAwsImageRequest(
    { ...dto.ogImage, isTransparent: true },
    {
      ...size,
      fit: "contain", // ✅ don't crop, add transparent space
      background: { r: 0, g: 0, b: 0, alpha: 0 }, // transparent background
    },
  );

  return {
    "fb:app_id": dto.facebookAppId,
    "og:site_name": dto.ogSiteName,
    "og:type": "website",
    "og:url": dto.ogUrl,
    "og:title": dto.ogTitle,
    "og:description": dto.ogDescription,
    "og:image": resizedImageUrl,
    "og:image:height": size.height.toString(),
    "og:image:width": size.width.toString(),
    ...(dto.ogText ? { "og:text": dto.ogText } : {}),
  };
}
