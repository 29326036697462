export type ImagePreviewSize = { width: number; height: number };
export const imageChannelSizeMapping: Record<string, ImagePreviewSize> = {
  facebook: {
    width: 1200,
    height: 630,
  },
  twitter: {
    width: 1200,
    height: 630,
  },
  whatsapp: {
    width: 800,
    height: 800,
  },
  pinterest: {
    // vertical
    width: 800,
    height: 1200,
  },
  linkedin: {
    //1.91:1 ratio
    width: 1200,
    height: 627,
  },
  link: {
    width: 800,
    height: 800,
  },
};
